import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Banner from 'components/Banner'
import Navigation from 'components/Navigation'
import History from './History'
import TwoDayComparison from './TwoDayComparison'
import CurrentDay from './CurrentDay'
import { Container, Divider } from './styles'

const Dessem = () => (
  <>
    <Banner title='PLD horário' />
    <Container>
      <Navigation
        options={[
          { label: 'Diário', value: '/dessem/current-day', base: 'dessem/current-day' },
          { label: 'Comparar dois dias', value: '/dessem/compare-two-days', base: 'dessem/compare-two-days' },
          { label: 'Histórico', value: '/dessem/price-history', base: 'dessem/price-history' },
        ]}
      />
      <Divider />
      <Switch>
        <Route exact path='/dessem/current-day' component={CurrentDay} />
        <Route exact path='/dessem/compare-two-days' component={TwoDayComparison} />
        <Route exact path='/dessem/price-history' component={History} />
      </Switch>
    </Container>
  </>
)

export default Dessem
