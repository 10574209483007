import React, { useState } from 'react'
import { useQuery } from 'react-query'
import api from 'services/api'
import SectionTitle from 'components/SectionTitle'
import SectionText from 'components/SectionText'
import { useParams } from 'react-router-dom'
import { PrismaSelect } from 'components/Input'
import { CustoMarginalDeOperacao } from './CustoMarginalDeOperacao'
import { VolumeUtilDosReservatorios } from './VolumeUtilDosReservatorios'
import { GeracaoHidraulica } from './GeracaoHidraulica'
import { GeracaoTermica } from './GeracaoTermica'
import { PequenasUsinas } from './PequenasUsinas'

type Response = {
  hash: string
  title: string
  created_at: string
  updated_at: string
}

const variables = [
  {
    name: 'Volume útil dos reservatórios',
    key: 'volume-util-dos-reservatorios',
    Component: VolumeUtilDosReservatorios,
  },
  {
    name: 'Custo marginal de operação',
    key: 'custo-marginal-de-operacao',
    Component: CustoMarginalDeOperacao,
  },
  {
    name: 'Geração hidráulica',
    key: 'geracao-hidraulica',
    Component: GeracaoHidraulica,
  },
  {
    name: 'Geração térmica',
    key: 'geracao-termica',
    Component: GeracaoTermica,
  },
  {
    name: 'Pequenas usinas',
    key: 'pequenas-usinas',
    Component: PequenasUsinas,
  },
]

export function Resultados() {
  const { hash } = useParams<{ hash: string }>()

  const [currentVariable, setCurrentVariable] = useState(variables[0])

  const { data: rodada } = useQuery(['decomp-oficial', hash], () => api.get<Response>(`model-results/decomp/${hash}`).then(({ data }) => data), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  })

  return (
    <>
      <SectionTitle>Decomp - Oficial CCEE</SectionTitle>
      <SectionText>{rodada?.title}</SectionText>
      <currentVariable.Component
        hash={hash}
        variableSelector={
          <PrismaSelect
            label='Variável'
            value={currentVariable.key}
            options={variables.map(({ key, name }) => ({ value: key, label: name }))}
            onChange={ev => setCurrentVariable(state => variables.find(entry => entry.key === ev.target.value) ?? state)}
          />
        }
      />
    </>
  )
}
