import styled, { keyframes } from 'styled-components'
import { darken, transparentize } from 'polished'

const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const dropdownItemAppear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const Container = styled.div<{ isThin?: boolean }>``

export const MenuContainer = styled.div``

export const Menu = styled.ul`
  list-style: none;
  display: flex;
  gap: 0.5rem;
`

export const MenuItem = styled.li<{ selected: boolean; position: number; hasSuboptions: boolean; isBlocked?: boolean }>`
  transition: 200ms;
  animation: ${appear} 200ms 1;
  animation-delay: ${props => props.position * 100}ms;
  background: ${props => {
    if (props.isBlocked) return props.theme.colors.black200
    if (props.selected && props.isBlocked) return props.theme.colors.black100
    if (props.selected) return props.theme.colors.blue100
    return props.theme.colors.blue700
  }};
  animation-fill-mode: backwards;
  min-width: 100px;
  text-align: center;
  border-radius: 5px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 5px;
  justify-content: center;
  svg {
    font-size: 20px;
    vertical-align: center;
    color: ${props => (props.selected ? props.theme.colors.blue700 : 'rgba(255, 255, 255, 0.5)')};
  }
  > a {
    text-decoration: none;
    color: ${props => {
      if (props.isBlocked) return '#fff'
      if (props.selected) return props.theme.colors.blue700
      return 'rgba(255, 255, 255, 0.8)'
    }};
    font-size: 0.875rem;
    transition: 200ms;
    display: flex;
    padding: 10px 10px 10px 5px;
    :hover {
      color: ${props => {
        if (props.isBlocked) return '#fff'
        if (props.selected) return props.theme.colors.blue700
        return 'rgba(255, 255, 255, 1)'
      }};
    }
  }
  div {
    display: none;
  }
  :hover div {
    display: flex;
  }
`

export const ItemDropdownContainer = styled.div`
  padding-top: 10px;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  min-width: 100%;
  z-index: 2;
  ul {
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    background: #fff;
    min-width: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
`

export const DropdownItem = styled.li<{ selected: boolean; position: number }>`
  animation: ${dropdownItemAppear} 200ms 1;
  animation-delay: ${props => props.position * 30}ms;
  animation-fill-mode: backwards;
  transition: background 200ms;
  > a {
    white-space: nowrap;
    text-decoration: none;
    color: ${props => (props.selected ? '#fff' : '#333')};
    font-size: 12px;
    transition: 200ms;
    display: block;
    padding: 10px;
  }
  background: ${props => (props.selected ? props.theme.safiraColors.blue[2] : '#fff')};
  :first-child {
    border-radius: 5px 5px 0 0;
  }
  :last-child {
    border-radius: 0 0 5px 5px;
  }
  :nth-child(even) {
    background: ${props => (props.selected ? 'default' : transparentize(0.9, props.theme.safiraColors.blue[3]))};
  }
  :nth-child(odd) {
    background: ${props => (props.selected ? 'default' : transparentize(0.95, props.theme.safiraColors.blue[3]))};
  }
  :hover {
    background: ${props => (props.selected ? 'default' : transparentize(0.8, props.theme.safiraColors.blue[3]))};
  }
`
