import { transparentize } from 'polished'
import styled from 'styled-components'

export const RodadasContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 2rem;
`

export const Rodada = styled.div<{ isFetching: boolean }>`
  transition: 200ms;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.03);
  border-radius: 0.5rem;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr auto;
  filter: ${({ isFetching }) => (isFetching ? 'opacity(0.5)' : '')};
  h1 {
    font-size: 1.2rem;
    font-weight: 700;
    color: #333;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
  p {
    font-size: 0.8rem;
    grid-row: 2;
    color: ${transparentize(0.5, '#333')};
  }
  a {
    grid-row: 1 / span 2;

    button {
      padding: 1rem;
      border: none;
      color: #fff;
      background: ${props => props.theme.colors.blue400};
      border: 1px solid ${props => props.theme.colors.blue700};
      border-radius: 5px;
      opacity: 0.7;
      transition: opacity 250ms;
      :hover {
        opacity: 1;
      }
    }
  }
`
