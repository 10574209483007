import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { ResponsiveContainer, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Label, Legend, ComposedChart, Line } from 'recharts'
import { useTheme } from 'styled-components'
import { ControllerContainer, LoadingContainer } from 'components/Chart'
import { PrismaSelect } from 'components/Input'
import api from 'services/api'

type Response = {
  semana: number
  patamar1: number
  patamar2: number
  patamar3: number
  total: number
}

export function GeracaoTermica({ variableSelector, hash }: { variableSelector: React.ReactNode; hash: string }) {
  const [usina, setUsina] = useState<number>()

  const theme = useTheme()

  const patamarColors = [theme.colors.green, theme.colors.yellow, theme.colors.red300]

  const patamarNames = ['leve', 'médio', 'pesado']

  const { data: usinas, isLoading: isLoadingUsinas } = useQuery(
    ['decomp-oficial', 'geracao-termica', 'usinas', hash],
    () =>
      api.get<{ no: number; usina: string }[]>(`model-results/decomp/${hash}/geracao-termica/usinas`).then(response => {
        setUsina(response.data[0]?.no)
        return response.data
      }),
    { refetchOnWindowFocus: false },
  )

  const { data, isLoading, isFetching } = useQuery(
    ['decomp-oficial', 'geracao-termica', hash, usina],
    () =>
      api
        .get<Response[]>(`model-results/decomp/${hash}/geracao-termica`, {
          params: { usina },
        })
        .then(response => response.data),
    { refetchOnWindowFocus: false, keepPreviousData: true, enabled: !!usinas && !!usina },
  )

  return (
    <>
      <ControllerContainer>
        {variableSelector}
        <PrismaSelect
          label='Usina'
          value={String(usina)}
          disabled={isLoadingUsinas}
          options={usinas?.map(usina => ({ value: String(usina.no), label: usina.usina }))}
          onChange={event => setUsina(Number(event.target.value))}
        />
      </ControllerContainer>
      <div style={{ height: '400px', position: 'relative', marginTop: '2rem' }}>
        {(() => {
          if (isLoading || isFetching || isLoadingUsinas) return <LoadingContainer />

          return (
            <ResponsiveContainer>
              <ComposedChart data={data}>
                <XAxis
                  padding={{ left: 30, right: 30 }}
                  tick={{ fontSize: '12px' }}
                  dataKey='semana'
                  interval={0}
                  tickLine={false}
                  tickFormatter={tick => `Semana ${tick}`}
                />
                <YAxis
                  tick={{ fontSize: '12px' }}
                  allowDecimals={false}
                  tickCount={8}
                  width={80}
                  domain={[0, 'auto']}
                  tickFormatter={tick => Number(tick).toLocaleString('pt-br')}
                  padding={{ top: 25 }}
                >
                  <Label
                    angle={-90}
                    value='MWmed'
                    fontSize={12}
                    position='insideLeft'
                    style={{ fontSize: '0.875rem', fill: theme.colors.black300, textAnchor: 'middle' }}
                  />
                </YAxis>
                <Tooltip
                  labelStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#ccc', marginBottom: '4px' }}
                  labelFormatter={label => `Semana ${label}`}
                  formatter={tick => `${Number(tick).toLocaleString('pt-br', { maximumFractionDigits: 2 })} MWmed`}
                />
                <CartesianGrid vertical={false} stroke='#f2f2f2' />
                {[1, 2, 3].map(patamar => (
                  <Bar
                    key={`bar-patamar-${patamar}`}
                    name={`Patamar ${patamarNames[patamar - 1]}`}
                    dataKey={`patamar${patamar}`}
                    fill={patamarColors[patamar - 1]}
                    stackId={1}
                  />
                ))}
                <Line
                  legendType='plainline'
                  type='monotone'
                  name='Total'
                  dataKey='total'
                  stroke={theme.colors.blue400}
                  strokeWidth={5}
                  label={({ x, y, value }: { x: number; y: number; value: number }) => (
                    <text x={x} y={y} dy={-10} fill={theme.colors.blue700} fontSize={12} textAnchor='middle'>
                      {value}
                    </text>
                  )}
                />
                <Legend wrapperStyle={{ fontSize: '14px' }} />
              </ComposedChart>
            </ResponsiveContainer>
          )
        })()}
      </div>
    </>
  )
}
