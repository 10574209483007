import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Pld } from './Pld'
import { Carga } from './Carga'
import { BalancoEnergetico } from 'pages/ModelResults/Dessem/BalancoEnergetico'
import { ValorDagua } from 'pages/ModelResults/Dessem/ValorDagua'

export function Dessem() {
  return (
    <Switch>
      <Route path='/resultados-dos-modelos/dessem/pld' component={Pld} />
      <Route path='/resultados-dos-modelos/dessem/carga' component={Carga} />
      <Route path='/resultados-dos-modelos/dessem/balanco-energetico' component={BalancoEnergetico} />
      <Route path='/resultados-dos-modelos/dessem/valor-da-agua' component={ValorDagua} />
    </Switch>
  )
}
