import React from 'react'
import { useQuery } from 'react-query'
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Label, ReferenceLine, Legend } from 'recharts'
import { useTheme } from 'styled-components'
import { ControllerContainer, LoadingContainer } from 'components/Chart'
import api from 'services/api'
import { subsystems } from 'utils/globals'

type Response = {
  semana: number
  sudeste: number
  sul: number
  nordeste: number
  norte: number
}

export function CustoMarginalDeOperacao({ variableSelector, hash }: { variableSelector: React.ReactNode; hash: string }) {
  const theme = useTheme()

  const { data, isLoading, isFetching } = useQuery(
    ['decomp-oficial', 'custo-marginal-de-operacao', hash],
    () => api.get<Response[]>(`/model-results/decomp/${hash}/custo-marginal-de-operacao`).then(response => response.data),
    { refetchOnWindowFocus: false, keepPreviousData: true },
  )

  const { data: pldLimits } = useQuery(
    ['dessem-oficial', 'pld', 'pld-limits', hash],
    () =>
      api
        .get<{ lowerLimit: number; upperLimit: number }>('/pld-limits', { params: { year: new Date().getFullYear() } })
        .then(response => response.data),
    { refetchOnWindowFocus: false, keepPreviousData: true },
  )

  return (
    <>
      <ControllerContainer>{variableSelector}</ControllerContainer>
      <div style={{ height: '400px', position: 'relative', marginTop: '2rem' }}>
        {(() => {
          if (isLoading || isFetching) return <LoadingContainer />

          return (
            <ResponsiveContainer>
              <BarChart data={data}>
                <XAxis
                  padding={{ left: 30, right: 30 }}
                  tick={{ fontSize: '12px' }}
                  dataKey='semana'
                  interval={0}
                  tickLine={false}
                  tickFormatter={tick => `Semana ${tick}`}
                />
                <YAxis
                  tick={{ fontSize: '12px' }}
                  allowDecimals={false}
                  tickCount={8}
                  width={80}
                  domain={[0, 'auto']}
                  tickFormatter={tick => Number(tick).toLocaleString('pt-br')}
                >
                  <Label
                    angle={-90}
                    value='CMO (R$/MWh)'
                    fontSize={12}
                    position='insideLeft'
                    style={{ fontSize: '0.875rem', fill: theme.colors.black300, textAnchor: 'middle' }}
                  />
                </YAxis>
                <Tooltip
                  labelStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#ccc', marginBottom: '4px' }}
                  labelFormatter={label => `Semana ${label}`}
                  formatter={tick => Number(tick).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                />
                <Legend wrapperStyle={{ fontSize: '14px' }} />
                <CartesianGrid vertical={false} stroke='#f2f2f2' />
                {subsystems.map(subsystem => (
                  <Bar key={subsystem.key} name={subsystem.name} dataKey={subsystem.keyPt} fill={subsystem.color} />
                ))}
                {pldLimits && (
                  <ReferenceLine y={pldLimits.lowerLimit} stroke={theme.colors.red400} opacity={0.5} strokeWidth={5} strokeDasharray='5 3' />
                )}
              </BarChart>
            </ResponsiveContainer>
          )
        })()}
      </div>
    </>
  )
}
