import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { useAuth } from 'oidc-react'
import NavBar from 'components/NavBar'
import Footer from 'components/Footer'
import Home from 'pages/Home'
import { Operation } from 'pages/Operation'
import { Regulatorio } from 'pages/Regulatorio'
import { Studies } from 'pages/Studies'
import Meteorology from 'pages/Meteorology'
import Market from 'pages/Market'
import Dessem from 'pages/Dessem'
import { Loading } from 'pages/Loading'
import { ModelResults } from 'pages/ModelResults'

const Routes: React.FC = () => {
  const { isLoading } = useAuth()

  if (isLoading) return <Loading />

  if (isMobile) window.location.href = process.env.REACT_APP_SAFIRA_ON_LOGIN_URL as string

  return (
    <Router>
      <NavBar />
      <Switch>
        <Route exact path='/home' component={Home} />
        <Route exact={false} path='/operation' component={Operation} />
        <Route exact={false} path='/regulatorio' component={Regulatorio} />
        <Route exact={false} path='/dessem' component={Dessem} />
        <Route exact={false} path='/meteorology' component={Meteorology} />
        <Route exact={false} path='/estudos' component={Studies} />
        <Route exact={false} path='/resultados-dos-modelos' component={ModelResults} />
        <Route exact={false} path='/market' component={Market} />
        <Route path='/*' component={() => <Redirect to='/home' />} />
      </Switch>
      <Footer />
    </Router>
  )
}

export default Routes
