import { add, startOfDay } from 'date-fns'

export const hourFromIper = (iper: number) =>
  add(startOfDay(new Date()), { minutes: Number(iper) * 30 }).toLocaleTimeString('pt-br', {
    hour: '2-digit',
    minute: '2-digit',
  })

export const returnBRLFromNumber = (numberToConvert: number): string => {
  return numberToConvert.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}

export const returnBRNumber = (numberToConvert?: number): string | undefined => {
  if (!numberToConvert) return '0'
  return numberToConvert.toLocaleString('pt-BR')
}

export const returnBRNumberWithoutDecimalPlaces = (numberToConvert?: number): string | undefined => {
  if (!numberToConvert) return '0'
  return numberToConvert.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
}

export const returnBRDateFormatFromUTCString = (stringToConvert: string): string => {
  return new Date(stringToConvert).toLocaleDateString('pt-BR', { timeZone: 'UTC' })
}

export const returnBRTimeFormatFromUTCString = (stringToConvert: string): string => {
  return new Date(stringToConvert).toLocaleTimeString('pt-BR', { timeZone: 'UTC' })
}

export function formatQueryDate(date: Date): string {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
}

/**
 * Returns a color string based on the number given. If the number equals 0, it return black, if it's smaller than 0, it returns red, otherwise it returns green
 */

export const returnColorFromNumber = (number: number): string => {
  if (number === 0) return 'black'

  if (number < 0) return 'red'

  return 'green'
}

export const capitalize = (word: string): string =>
  `${word.replace('.', '').split('').shift()?.toUpperCase()}${word.replace('.', '').split('').splice(1).join('')}`

export const numberToBrl = (number: number): string => {
  if (typeof number !== 'number' || Number.isNaN(number)) return '-'
  return number.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
}

export const getNumberColor = (value: number): string => {
  if (isNaN(value)) return ''
  if (value < 0) return 'red'
  if (value > 0) return 'green'
  return ''
}

export const getUtcIsoStringFromDate = (date: Date): string => add(date, { minutes: -date.getTimezoneOffset() }).toISOString()

export class PrismaChartUtils {
  static getChartTicks = ({ payload, numberOfTicks = 5, rounder }: { payload: number[]; numberOfTicks?: number; rounder?: number }): number[] => {
    const maxValue = Math.max(...payload)
    const minValue = Math.min(...payload)

    let maxRoundedValue = Math.ceil(maxValue / numberOfTicks) * numberOfTicks
    let minRoundedValue = Math.floor(minValue / numberOfTicks) * numberOfTicks

    if (rounder) {
      maxRoundedValue = Math.ceil(maxValue / rounder) * rounder
      minRoundedValue = Math.floor(minValue / rounder) * rounder
    }

    if (maxRoundedValue === maxValue) maxRoundedValue += maxValue / 10

    const difference = (maxRoundedValue - minRoundedValue) / numberOfTicks

    const ticks: number[] = [minRoundedValue, ...new Array(numberOfTicks).fill(1).map((_entry, index) => minRoundedValue + (index + 1) * difference)]

    return ticks
  }

  static getChartGradientOffset = ({ data }: { data: number[] }): number => {
    const dataMax = Math.max(...data)
    const dataMin = Math.min(...data)
    if (dataMax <= 0) return 0
    if (dataMin >= 0) return 1
    return dataMax / (dataMax - dataMin)
  }
}

export const utcDateFromDate = (date: Date): Date => {
  const offset = date.getTimezoneOffset()
  return add(date, { minutes: offset })
}

export function getImageUrl(path: string): string {
  const basePath = process.env.REACT_APP_STATIC_ADDRESS

  return `${basePath}/${path}`
}
