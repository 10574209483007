import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Rodadas } from 'pages/ModelResults/Newave/Rodadas'
import { Resultados } from 'pages/ModelResults/Newave/Resultados'

export function Newave() {
  return (
    <Switch>
      <Route path='/resultados-dos-modelos/newave' exact component={Rodadas} />
      <Route path='/resultados-dos-modelos/newave/:hash' exact component={Resultados} />
    </Switch>
  )
}
