import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Label } from 'recharts'
import { useTheme } from 'styled-components'
import { ControllerContainer, LoadingContainer } from 'components/Chart'
import { PrismaSelect } from 'components/Input'
import api from 'services/api'

export function VolumeUtilDosReservatorios({ variableSelector, hash }: { variableSelector: React.ReactNode; hash: string }) {
  const [usina, setUsina] = useState<number>()

  const theme = useTheme()

  const { data: usinas, isLoading: isLoadingUsinas } = useQuery(
    ['decomp-oficial', 'volume-util-dos-reservatorios', 'usinas', hash],
    () =>
      api.get<{ no: number; usina: string }[]>(`model-results/decomp/${hash}/volume-util-dos-reservatorios/usinas`).then(response => {
        setUsina(response.data[0]?.no)
        return response.data
      }),
    { refetchOnWindowFocus: false },
  )

  const { data, isLoading, isFetching } = useQuery(
    ['decomp-oficial', 'volume-util-dos-reservatorios', hash, usina],
    () =>
      api
        .get<{ date: string; semana: number; volume: number }[]>(`/model-results/decomp/${hash}/volume-util-dos-reservatorios`, {
          params: { usina },
        })
        .then(response => response.data),
    { refetchOnWindowFocus: false, keepPreviousData: true, enabled: !!usinas && !!usina },
  )

  return (
    <>
      <ControllerContainer>
        {variableSelector}
        <PrismaSelect
          label='Usina'
          value={String(usina)}
          disabled={isLoadingUsinas}
          options={usinas?.map(usina => ({ value: String(usina.no), label: usina.usina }))}
          onChange={event => setUsina(Number(event.target.value))}
        />
      </ControllerContainer>
      <div style={{ height: '400px', position: 'relative', marginTop: '2rem' }}>
        {(() => {
          if (isLoading || isFetching || isLoadingUsinas) return <LoadingContainer />

          return (
            <ResponsiveContainer>
              <LineChart data={data}>
                <XAxis
                  padding={{ left: 30, right: 30 }}
                  tick={{ fontSize: '12px' }}
                  dataKey='semana'
                  interval={0}
                  tickLine={false}
                  tickFormatter={tick => {
                    if (tick === 0) return 'Valor inicial'
                    return `Semana ${tick}`
                  }}
                />
                <YAxis
                  tick={{ fontSize: '12px' }}
                  allowDecimals={false}
                  tickCount={8}
                  width={80}
                  domain={['auto', 'auto']}
                  tickFormatter={tick => Number(tick).toLocaleString('pt-br')}
                >
                  <Label
                    angle={-90}
                    value='Volume útil (%)'
                    fontSize={12}
                    position='insideLeft'
                    style={{ fontSize: '0.875rem', fill: theme.colors.black300, textAnchor: 'middle' }}
                  />
                </YAxis>
                <Tooltip
                  labelStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#ccc', marginBottom: '4px' }}
                  labelFormatter={label => {
                    if (label === 0) return 'Valor inicial'
                    return `Semana ${label}`
                  }}
                  formatter={tick =>
                    (Number(tick) / 100).toLocaleString('pt-br', {
                      style: 'percent',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  }
                />
                <CartesianGrid vertical={false} stroke='#f2f2f2' />
                <Line
                  type='monotone'
                  name='Volume útil (%)'
                  dataKey='volume'
                  stroke={theme.colors.blue400}
                  strokeWidth={5}
                  label={({ x, y, value }: { x: number; y: number; value: number }) => {
                    if (!data) return

                    const volumes = data.map(entry => entry.volume)

                    const min = Math.min(...volumes)

                    const dy = value - min > (Math.max(...volumes) - min) / 2 ? 25 : -15

                    return (
                      <text x={x} y={y} dy={dy} fill={theme.colors.blue700} fontSize={12} textAnchor='middle'>
                        {value}
                      </text>
                    )
                  }}
                />
              </LineChart>
            </ResponsiveContainer>
          )
        })()}
      </div>
    </>
  )
}
