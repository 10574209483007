import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Label } from 'recharts'
import { useTheme } from 'styled-components'
import { LoadingContainer, ControllerContainer } from 'components/Chart'
import { InputDate } from 'components/Input'
import api from 'services/api'
import SectionTitle from 'components/SectionTitle'
import SectionText from 'components/SectionText'
import { hourFromIper } from 'utils'
import { subsystems } from 'utils/globals'
import { format, parse } from 'date-fns'

type Response = {
  date: string
  iper: number
  pat: string
  sist: string
  cmarg: number
}[]

export function Carga() {
  const [date, setDate] = useState(new Date())

  const theme = useTheme()

  const { data: southeastData, isLoading: isLoadingSoutheast } = useQuery(
    ['dessem-oficial', 'carga', 'SE', date],
    () => api.get<Response>('/model-results/dessem/carga', { params: { date, sist: 'SE' }, timeout: 3000 }).then(response => response.data),
    { refetchOnWindowFocus: false, keepPreviousData: true },
  )

  const { data: southData, isLoading: isLoadingSouth } = useQuery(
    ['dessem-oficial', 'carga', 'S', date],
    () => api.get<Response>('/model-results/dessem/carga', { params: { date, sist: 'S' }, timeout: 3000 }).then(response => response.data),
    { refetchOnWindowFocus: false, keepPreviousData: true },
  )

  const { data: northeastData, isLoading: isLoadingNortheast } = useQuery(
    ['dessem-oficial', 'carga', 'NE', date],
    () => api.get<Response>('/model-results/dessem/carga', { params: { date, sist: 'NE' }, timeout: 3000 }).then(response => response.data),
    { refetchOnWindowFocus: false, keepPreviousData: true },
  )

  const { data: northData, isLoading: isLoadingNorth } = useQuery(
    ['dessem-oficial', 'carga', 'N', date],
    () => api.get<Response>('/model-results/dessem/carga', { params: { date, sist: 'N' }, timeout: 3000 }).then(response => response.data),
    { refetchOnWindowFocus: false },
  )

  const charts = useMemo(
    () => [
      {
        name: 'Sudeste',
        data: southeastData,
        color: subsystems[0].color,
      },
      {
        name: 'Sul',
        data: southData,
        color: subsystems[1].color,
      },
      {
        name: 'Nordeste',
        data: northeastData,
        color: subsystems[2].color,
      },
      {
        name: 'Norte',
        data: northData,
        color: subsystems[3].color,
      },
    ],
    [southeastData, southData, northeastData, northData],
  )

  return (
    <>
      <SectionTitle>DESSEM</SectionTitle>
      <SectionText>Carga.</SectionText>
      <ControllerContainer>
        <InputDate
          label='Data'
          max={format(new Date(), 'yyyy-MM-dd')}
          value={format(date, 'yyyy-MM-dd')}
          onChange={({ target }) => setDate(parse(target.value, 'yyyy-MM-dd', new Date()))}
        />
      </ControllerContainer>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '2rem', marginTop: '2rem' }}>
        {charts.map(chart => (
          <div key={chart.name}>
            <h2 style={{ color: chart.color, marginLeft: '1rem' }}>{chart.name}</h2>
            <div style={{ height: '350px', position: 'relative', marginTop: '2rem' }}>
              {(() => {
                if (isLoadingSoutheast || isLoadingSouth || isLoadingNortheast || isLoadingNorth) return <LoadingContainer />

                return (
                  <ResponsiveContainer>
                    <LineChart data={chart.data} syncId={1}>
                      <XAxis
                        tick={{ fontSize: '12px' }}
                        dataKey='iper'
                        interval={0}
                        tickLine={false}
                        ticks={chart.data
                          ?.filter(entry => entry.iper % 4 === 0)
                          .map(entry => entry.iper)
                          .slice(0, -1)}
                        tickFormatter={hourFromIper}
                      />
                      <YAxis
                        tick={{ fontSize: '12px' }}
                        tickCount={8}
                        domain={['auto', 'auto']}
                        width={80}
                        tickFormatter={tick => Number(tick).toLocaleString('pt-br')}
                      >
                        <Label
                          angle={-90}
                          value='MWmed'
                          fontSize={12}
                          position='insideLeft'
                          style={{ fontSize: '0.875rem', fill: theme.colors.black300, textAnchor: 'middle' }}
                        />
                      </YAxis>
                      <Tooltip
                        labelStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#ccc', marginBottom: '4px' }}
                        labelFormatter={label => `${hourFromIper(Number(label))}h`}
                        formatter={tick => `${Number(tick).toLocaleString('pt-br')} MWmed`}
                      />
                      <CartesianGrid vertical={false} stroke='#f2f2f2' />
                      <Line type='monotone' name='Carga' dataKey='demanda' stroke={chart.color} strokeWidth={5} />
                    </LineChart>
                  </ResponsiveContainer>
                )
              })()}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
