import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Banner from 'components/Banner'
import Navigation from 'components/Navigation'
import { Dessem } from 'pages/ModelResults/Dessem'
import { Container, Divider } from './styles'
import { Decomp } from 'pages/ModelResults/Decomp'
import { Newave } from 'pages/ModelResults/Newave'

export function ModelResults() {
  return (
    <>
      <Banner title='Resultados dos modelos' hasNavigation />
      <Container>
        <Navigation
          options={[
            {
              label: 'Dessem',
              value: '/resultados-dos-modelos/dessem/pld',
              base: '/resultados-dos-modelos/dessem',
              suboptions: [
                {
                  label: 'PLD',
                  value: '/resultados-dos-modelos/dessem/pld',
                },
                {
                  label: 'Carga',
                  value: '/resultados-dos-modelos/dessem/carga',
                },
                {
                  label: 'Balanço energético',
                  value: '/resultados-dos-modelos/dessem/balanco-energetico',
                },
                {
                  label: 'Valor da água',
                  value: '/resultados-dos-modelos/dessem/valor-da-agua',
                },
              ],
            },
            {
              label: 'Decomp',
              value: '/resultados-dos-modelos/decomp',
              base: '/resultados-dos-modelos/decomp',
            },
            {
              label: 'Newave',
              value: '/resultados-dos-modelos/newave',
              base: '/resultados-dos-modelos/newave',
            },
          ]}
        />
        <Divider />
        <Switch>
          <Route path='/resultados-dos-modelos/dessem' component={Dessem} />
          <Route path='/resultados-dos-modelos/decomp' component={Decomp} />
          <Route path='/resultados-dos-modelos/newave' component={Newave} />
        </Switch>
      </Container>
    </>
  )
}
