import React, { useState } from 'react'
import { useQuery } from 'react-query'
import api from 'services/api'
import SectionTitle from 'components/SectionTitle'
import SectionText from 'components/SectionText'
import { useParams } from 'react-router-dom'
import { ControllerContainer, LoadingContainer } from 'components/Chart'
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Label, Legend } from 'recharts'
import { useTheme } from 'styled-components'
import { capitalize } from 'lodash'
import { subsystems } from 'utils/globals'
import { PrismaSelect } from 'components/Input'
import InputRadio from 'components/InputRadio'

type Response = {
  hash: string
  title: string
  month: number
  created_at: string
  updated_at: string
}

const toBrl = (value: number) => value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })

const variables = [
  {
    name: 'Custo marginal de operação',
    key: 'custo-marginal-de-operacao',
    label: 'R$/MWh',
    isSubsystem: true,
    formatter: (value: number) => `${toBrl(value)}/MWh`,
  },
  {
    name: 'Energia natural afluente',
    key: 'energia-natural-afluente',
    label: 'MWmês',
    isSubsystem: true,
    formatter: (value: number) => `${value.toLocaleString('pt-br')} MWmês`,
  },
  {
    name: 'Energia vertida',
    key: 'energia-vertida',
    label: 'MWmês',
    isSubsystem: true,
    formatter: (value: number) => `${value.toLocaleString('pt-br')} MWmês`,
  },
  {
    name: 'Geracao hidráulica',
    key: 'geracao-hidraulica',
    label: 'MWmês',
    isSubsystem: true,
    formatter: (value: number) => `${value.toLocaleString('pt-br')} MWmês`,
  },
  {
    name: 'Geracao térmica',
    key: 'geracao-termica',
    label: 'MWmês',
    isSubsystem: true,
    formatter: (value: number) => `${value.toLocaleString('pt-br')} MWmês`,
  },
  {
    name: 'Intercâmbio',
    key: 'intercambio',
    label: 'MWmês',
    isSubsystem: false,
    formatter: (value: number) => `${value.toLocaleString('pt-br')} MWmês`,
  },
  {
    name: 'Valor da água',
    key: 'valor-dagua',
    label: 'R$/MWh',
    isSubsystem: true,
    formatter: (value: number) => `${toBrl(value)}/MWh`,
  },
]

const charts = [
  {
    name: 'Média',
    key: 'media',
  },
  {
    name: 'Desvio padrão',
    key: 'dpadrao',
  },
  {
    name: 'Percentil 95',
    key: 'p95',
  },
  {
    name: 'Percentil 5',
    key: 'p5',
  },
]

const colors = ['#83d123', '#600000', '#e56910', '#008000', '#ff1a75', '#12519e', '#B8B799', '#ffff00', '#f6ad79', '#009900']

export function Resultados() {
  const { hash } = useParams<{ hash: string }>()

  const theme = useTheme()

  const [currentVariable, setCurrentVariable] = useState(variables[0])

  const [currentChart, setCurrentChart] = useState(charts[0])

  const { data: rodada } = useQuery(['newave-oficial', hash], () => api.get<Response>(`model-results/newave/${hash}`).then(({ data }) => data), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  })

  const { data, isLoading, isFetching } = useQuery(
    ['newave-oficial', currentVariable, hash],
    () =>
      api
        .get<{ year: number; month: number }[]>(`/model-results/newave/${hash}/${currentVariable.key}`, {
          timeout: 10000,
        })
        .then(response => {
          return response.data.slice(rodada?.month ? rodada.month - 1 : 0, response.data.length - 1).slice(0, 13)
        }),
    { refetchOnWindowFocus: false, keepPreviousData: true, enabled: !!rodada },
  )

  return (
    <>
      <SectionTitle>Newave - Oficial CCEE</SectionTitle>
      <SectionText>{rodada?.title}</SectionText>
      <ControllerContainer>
        <PrismaSelect
          label='Variável'
          value={currentVariable.key}
          options={variables.map(({ key, name }) => ({ value: key, label: name }))}
          onChange={ev => setCurrentVariable(state => variables.find(entry => entry.key === ev.target.value) ?? state)}
        />
        <InputRadio
          label='Gráfico'
          name='newave-oficial-chart'
          options={charts.map(chart => ({ label: chart.name, value: chart.key, checked: currentChart.key === chart.key }))}
          onChange={ev => setCurrentChart(chart => charts.find(entry => entry.key === ev.target.value) ?? chart)}
        />
      </ControllerContainer>
      <div style={{ height: '400px', position: 'relative', marginTop: '2rem' }}>
        {(() => {
          if (isLoading || isFetching) return <LoadingContainer />

          return (
            <ResponsiveContainer>
              <BarChart data={data?.map(entry => ({ ...entry, date: new Date(entry.year, entry.month - 1, 1).toString() }))}>
                <XAxis
                  tick={{ fontSize: '12px' }}
                  dataKey='date'
                  type='category'
                  tickLine={false}
                  tickFormatter={tick => new Date(tick).toLocaleDateString('pt-br', { month: 'short', year: '2-digit' })}
                />
                <YAxis tick={{ fontSize: '12px' }}>
                  <Label
                    angle={-90}
                    value={currentVariable.label}
                    fontSize={12}
                    position='insideLeft'
                    style={{ fontSize: '0.875rem', fill: theme.colors.black300, textAnchor: 'middle' }}
                  />
                </YAxis>
                <Tooltip
                  labelStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#ccc', marginBottom: '4px' }}
                  formatter={value => currentVariable.formatter(Number(value))}
                  labelFormatter={label =>
                    capitalize(
                      new Date(label).toLocaleDateString('pt-br', {
                        year: 'numeric',
                        month: 'long',
                      }),
                    )
                  }
                />
                <CartesianGrid vertical={false} stroke='#f2f2f2' />
                <Legend wrapperStyle={{ fontSize: '12px' }} />
                {(() => {
                  if (currentVariable.isSubsystem)
                    return subsystems.map(subsystem => (
                      <Bar key={subsystem.name} name={subsystem.name} dataKey={`${subsystem.keyPt}.${currentChart.key}`} fill={subsystem.color} />
                    ))

                  return Object.keys(data?.[0] ?? {})
                    .filter(entry => entry !== 'year' && entry !== 'month')
                    .map((entry, index) => <Bar key={entry} name={entry} dataKey={`${entry}.${currentChart.key}`} fill={colors[index]} />)
                })()}
              </BarChart>
            </ResponsiveContainer>
          )
        })()}
      </div>
    </>
  )
}
