import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { BsFillDoorOpenFill } from 'react-icons/bs'
import { useAuth } from 'oidc-react'
import { CompanyContext } from 'CompanyProvider'

import { Container, LogoContainer, MenuContainer, MenuItem, UserContainer, DynamicMapLink } from './styles'

type NavItem = {
  text: string
  path: string
  base: string
  companyLevel: number
}

const NavBar: React.FC = () => {
  const location = useLocation()

  const companyLevel = useContext(CompanyContext)

  const auth = useAuth()

  const navItems: NavItem[] = [
    {
      text: 'Home',
      path: '/home',
      base: '/home',
      companyLevel: 0,
    },
    {
      text: 'Operação',
      path: '/operation/real-time/acomplished',
      base: '/operation',
      companyLevel: 0,
    },
    {
      text: 'Regulatório',
      path: '/regulatorio',
      base: '/regulatorio',
      companyLevel: 1,
    },
    {
      text: 'PLD horário',
      path: '/dessem/current-day',
      base: '/dessem',
      companyLevel: 0,
    },
    {
      text: 'Meteorologia',
      path: companyLevel >= 1 ? '/meteorology/reports' : '/meteorology/acomplished',
      base: '/meteorology',
      companyLevel: 0,
    },
    {
      text: 'Estudos',
      path: '/estudos/curtissimo-prazo',
      base: '/estudos',
      companyLevel: 1,
    },
    {
      text: 'Resultados dos Modelos',
      path: '/resultados-dos-modelos/dessem/pld',
      base: '/resultados-dos-modelos',
      companyLevel: 1,
    },
    {
      text: 'Mercado',
      path: '/market/forward-curve/by-day',
      base: '/market',
      companyLevel: 1,
    },
  ]

  return (
    <Container>
      <LogoContainer>
        <a href={process.env.REACT_APP_SAFIRA_ON_LOGIN_URL}>
          <img src={`${process.env.REACT_APP_STATIC_ADDRESS}/images/client/safira-on-white.png`} alt='Safira On Logo' />
        </a>
      </LogoContainer>
      <MenuContainer>
        <ul>
          {navItems
            .filter(navItem => companyLevel >= navItem.companyLevel)
            .map((navItem, index) => {
              const active = location.pathname.split('/').slice(0, 2).join('/') === navItem.base

              return (
                <li key={navItem.path}>
                  <MenuItem position={index + 1} to={navItem.path} active={active}>
                    {navItem.text}
                  </MenuItem>
                </li>
              )
            })}
          <li>
            <DynamicMapLink
              target='_blank'
              rel='noreferer noopener'
              href={`https://mapa.inteligencia.safiraenergia.com.br?user=${auth.userData?.profile.email}`}
            >
              Mapa dinâmico
            </DynamicMapLink>
          </li>
        </ul>
      </MenuContainer>
      <UserContainer>
        <span>{auth.userData?.profile?.name}</span>
        <button type='button' onClick={() => auth.signOutRedirect()}>
          <BsFillDoorOpenFill title='Sair' />
        </button>
      </UserContainer>
    </Container>
  )
}

export default NavBar
