import React from 'react'
import { useQuery } from 'react-query'
import api from 'services/api'
import SectionTitle from 'components/SectionTitle'
import SectionText from 'components/SectionText'
import { RodadasContainer, Rodada } from './styles'
import { Link } from 'react-router-dom'
import { RectShape } from 'react-placeholder/lib/placeholders'

type Response = {
  hash: string
  title: string
  month: number
  created_at: string
  updated_at: string
}[]

export function Rodadas() {
  const { data, isLoading, isFetching } = useQuery(
    ['newave-oficial', 'rodadas'],
    () => api.get<Response>('/model-results/newave', { timeout: 3000 }).then(({ data }) => data),
    { keepPreviousData: true },
  )

  return (
    <>
      <SectionTitle>Newave - Oficial CCEE</SectionTitle>
      <SectionText>Escolha uma rodada para explorar os resultados.</SectionText>
      <RodadasContainer>
        {isLoading &&
          Array.from({ length: 3 }).map((_, index) => <RectShape key={index} color='#ccc' style={{ height: '5.25rem', borderRadius: '1rem' }} />)}
        {data?.map(rodada => (
          <Rodada key={rodada.hash} isFetching={isFetching}>
            <h1>{rodada.title}</h1>
            <p>{new Date(rodada.created_at).toLocaleString()}</p>
            <Link to={`/resultados-dos-modelos/newave/${rodada.hash}`}>
              <button>Resultados</button>
            </Link>
          </Rodada>
        ))}
      </RodadasContainer>
    </>
  )
}
