import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Rodadas } from './Rodadas'
import { Resultados } from './Resultados'

export function Decomp() {
  return (
    <Switch>
      <Route path='/resultados-dos-modelos/decomp' exact component={Rodadas} />
      <Route path='/resultados-dos-modelos/decomp/:hash' exact component={Resultados} />
    </Switch>
  )
}
